import {
  useState,
  useEffect,
  useRef,
  useCallback,
  SyntheticEvent,
  useMemo,
} from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useLoading } from "./context/loading-context";
import { useAppDispatch, useAppSelector } from "./selectors/selectors";
import { TCustomError, useErrorHandler } from "./context/error-handler-context";
import axios from "./utils/axios";
import { sec } from "./utils/security";
import { getItem, removeItem } from "./utils/storage";
import Snackbar from "@mui/material/Snackbar";
import Offer from "./components/Pages/Offer";
import Login from "./components/Pages/Login";
import Registration from "./components/Pages/Registration/Registration";
import ApplicationTerms from "./components/Pages/Application/ApplicationTerms";

// partner
import PartnerOverview from "./components/Pages/Partner/PartnerOverview";
import PartnerFinancing from "./components/Pages/Partner/PartnerFinancing";
import PartnerCommissions from "./components/Pages/Partner/PartnerCommissions";

import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Loading from "./components/UI/Loading";
import { useQuery, useRole, useScreenSize } from "./hooks";
import Alert from "./components/UI/Alert";
import {
  ActiveApplicationDto,
  ExternalUserDto,
  ExternalUserDtoCustomerDto,
  LeadDto,
  LoansOverviewDto,
  PartnerConnectionDto,
  PartnerLoansOverviewDto,
  UserType,
} from "./types/api";
import { setCurrentUserAction } from "./store/slices/currentUserSlice";
import {
  calculateAmountToPayForInstallments,
  parseErrorMessage,
  safeParseJSON,
} from "./utils/utils";
import { getUtmFromStorage } from "./utils/utm";
import {
  setCurrentCustomerAction,
  setDefaultCurrencyAction,
} from "./store/slices/currentCustomerSlice";
import { setCurrentCustomerOffer } from "./store/slices/offerSlice";
import PartnerRoutes from "./router/PartnerRoutes";
import CustomerRoutes from "./router/CustomerRoutes";
import { setApplicationAction } from "./store/slices/applicationSlice";
import { defaultCurrencies } from "./constants/defaultCurrencies";
import i18n from "./i18n/config";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ScheduledAgreement from "./components/Pages/Application/ScheduledAgreement";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  const splitLocation = pathname.split("/").filter(Boolean);
  const { isMediumScreen } = useScreenSize();
  const { currentCustomer } = useAppSelector((state) => state.currentCustomer);
  const defaultLanguage = useAppSelector((state) => state.language);

  const { getAccessTokenSilently, isLoading, isAuthenticated, user, logout } =
    useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);

  const [customerId, setCustomerId] = useState<string | null>(null);
  const [partner, setPartner] = useState<PartnerConnectionDto | null>(null);
  const [currentUser, setCurrentUser] = useState<ExternalUserDto | null>(null);
  const [loan, setLoan] = useState<LoansOverviewDto | null>(null);
  const [partnerOverview, setPartnerOverview] =
    useState<PartnerLoansOverviewDto | null>(null);
  const [applicationData, setApplicationData] =
    useState<ActiveApplicationDto | null>(null);
  const [leadData, setLeadData] = useState<LeadDto | null>(null);
  const { loading, setLoading } = useLoading();
  const { error, setError } = useErrorHandler();
  const role = useRole();
  const dispatch = useAppDispatch();

  let loggedUserId = useRef(null);

  const query = useQuery();
  const error_query = query.get("error");
  const error_description = query.get("error_description");

  const handleCloseErrorAlert = (
    event: SyntheticEvent<Element, Event> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setError(null);
  };

  const changePartner = (partner: PartnerConnectionDto) => {
    setPartner(partner);
  };

  const handleReloadStatus = () => {
    if (customerId) {
      setLoading(true);
      setTimeout(() => {
        fetchCustomerRelatedData(customerId).finally(() => setLoading(false));
      }, 150); // intentionally show loading spinner
    }
  };

  const fetchCustomerRelatedData = async (customerId: string) => {
    setLoading(true);

    try {
      const [resApplication, resLead, resLoans, resOffer] = await Promise.all([
        axios.get(`applications/active/customer/${customerId}`),
        axios.get(`leads/active/customer/${customerId}`),
        axios.get(`loans/customers/${customerId}`),
        axios.get(`offers/active/customer/${customerId}`),
      ]);

      dispatch(setCurrentCustomerOffer(resOffer.data));
      setCustomerId(customerId);
      setLoan(resLoans.data);
      dispatch(setApplicationAction(resApplication?.data));
      setApplicationData(resApplication?.data);
      setLeadData(resLead?.data);

      setLoading(false);

      return { resApplication, resLead, resLoans, resOffer };
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  const navigateBasedOnData = (customerId: string, data: any) => {
    const { resApplication, resLead, resLoans, resOffer } = data;
    const appData = resApplication?.data;

    const storedRequestedPage = localStorage.getItem("requestedPage");

    const splitstoredRequestedPageLocation = storedRequestedPage?.split("/");
    const navigatingFromOfferPage =
      splitstoredRequestedPageLocation &&
      splitstoredRequestedPageLocation[0] === "offer";

    if (
      storedRequestedPage &&
      storedRequestedPage !== "/" &&
      !navigatingFromOfferPage //Checking, if initial requested page was not offer page
    ) {
      navigate(storedRequestedPage);
      localStorage.removeItem("nonce");
      localStorage.removeItem("requestedPage");
    } else {
      localStorage.removeItem("requestedPage");

      if (
        resApplication &&
        resApplication.data &&
        resApplication.data.application.active
      ) {
        const isRep2 =
          appData.application.rep2Id &&
          appData.application.rep2Id === loggedUserId.current;

        if (isRep2) {
          const isRep2ActionRequired =
            isRep2 &&
            ["NEW", "SUBMITTED", "CHECKING", "SENT_FOR_APPROVING"].includes(
              appData.application.state
            ) &&
            !appData.rep2KycValid;

          if (isRep2ActionRequired) {
            navigate(`/${customerId}/application`);
          }
        } else if (
          splitLocation.includes("application") &&
          splitLocation.includes("psd") &&
          splitLocation.includes("completed")
        ) {
          navigate(`/${customerId}/application/psd/completed`);
        } else if (
          splitLocation.includes("connections") &&
          splitLocation.includes("psd") &&
          splitLocation.includes("completed")
        ) {
          navigate(`/${customerId}/connections/psd/completed`);
        }
      } else if (resLead.data && resLead.data.state === "INTEREST") {
        const appData = {
          application: {
            leadId: resLead.data.id,
            offerId: resLead.data.offerId,
            inputState: resLead.data.state,
            partnerCode: resLead.data.partnerCode,
            merchantId: resLead.data.merchantId,
          },
          product: resLead.data.product,
        } as any;

        setApplicationData(appData);
        navigate(`/${customerId}/application`);
      } else if ((resLoans && resLoans.data) || (resOffer && resOffer.data)) {
        navigate(`${customerId}/overview`);
      } else {
        navigate(`${customerId}/connections`);
      }
    }
  };

  const selectCustomerHandler = async (
    customerId: string,
    selectedPartner: PartnerConnectionDto | null
  ) => {
    try {
      localStorage.setItem("customer_id", JSON.stringify(customerId));
      const data = await fetchCustomerRelatedData(customerId);
      setPartner(selectedPartner);

      navigateBasedOnData(customerId, data);
    } catch (err) {
      setLoading(false);
      setError(err as TCustomError);
    }
  };

  const updateApplicationData = (
    application: ActiveApplicationDto["application"]
  ): void => {
    setApplicationData(
      (prevState: ActiveApplicationDto | null): ActiveApplicationDto | null => {
        if (application && prevState) return { ...prevState, application };
        return null;
      }
    );
  };

  const getPartnerOverview = (customerId: string) => {
    setLoading(true);
    axios
      .get(`loans/partners/${customerId}/overview`)
      .then((res) => {
        setLoading(false);
        setCustomerId(customerId);
        const partnerOverviewData = res.data;
        if (!partnerOverviewData.monthlyInCirculation)
          partnerOverviewData.monthlyInCirculation = [];
        if (!partnerOverviewData.monthlyDisbursed)
          partnerOverviewData.monthlyDisbursed = [];
        if (!partnerOverviewData.monthlyPaidCommissions)
          partnerOverviewData.monthlyPaidCommissions = [];
        if (!partnerOverviewData.monthlyExpectedCommissions)
          partnerOverviewData.monthlyExpectedCommissions = [];
        setPartnerOverview(partnerOverviewData);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const loadCurrentUser = async (): Promise<ExternalUserDto> => {
    setLoading(true);
    try {
      const res = await axios.get("users/current");
      loggedUserId.current = res.data.userId;
      setLoading(false);
      dispatch(setCurrentUserAction(res.data as ExternalUserDto));
      const customerId = splitLocation[0];
      let currentCustomer: ExternalUserDtoCustomerDto;
      //To parse the url and get correct customer id
      if (customerId && !["signup", "signin"].includes(customerId)) {
        currentCustomer = res.data.customers?.find(
          (customer: ExternalUserDtoCustomerDto) => {
            return customer.id === customerId;
          }
        );
      } else {
        currentCustomer = res.data?.customers?.[0];
      }
      dispatch(setCurrentCustomerAction(currentCustomer));
      const defaultCurrency =
        defaultCurrencies[currentCustomer?.country ?? defaultCurrencies.CZ];
      dispatch(setDefaultCurrencyAction(defaultCurrency));

      return res.data;
    } catch (error) {
      setLoading(false);
      setError(error as TCustomError);
      throw error;
    }
  };

  const loadCustomerData = async (customerId: string, leadId: string) => {
    try {
      if (leadId) {
        const response = await axios.post(
          `partner-connections/customers/${customerId}`,
          { leadId }
        );
        return response.data;
      } else {
        return {};
      }
    } catch (error) {
      setError(error as TCustomError);
      throw error;
    }
  };

  const handleCustomerData = async (
    currentUser: ExternalUserDto,
    leadId: string,
    customerId: string
  ) => {
    try {
      const partner = currentUser?.customers?.[0]?.partners
        ? currentUser.customers[0].partners[0]
        : null;

      if (leadId) {
        removeItem();
      }

      setCurrentUser(currentUser);

      // const isOfferUrl =
      //   splitLocation &&
      //   (splitLocation[0] === 'entry' ||
      //     (splitLocation.length >= 3 && splitLocation[0] === 'offer')); //! We will need this logic later

      selectCustomerHandler(customerId, partner);

      setCustomerId(customerId);
    } catch (error) {
      setError(error as TCustomError);
    }
  };

  const createLead = async (userId: string, email: string) => {
    try {
      const utm_params = getUtmFromStorage();

      let offerLeadId: string | null = null;

      try {
        const unparsedLeadId = localStorage.getItem("lead_id");
        if (unparsedLeadId) {
          offerLeadId = JSON.parse(unparsedLeadId);
        }
      } catch (parseError) {
        console.error("Failed to parse lead_id from localStorage:", parseError);
      }

      const params: Record<string, any> = {
        ...utm_params,
        userId,
        email,
      };

      if (offerLeadId) {
        params.leadId = offerLeadId;
      }

      const res = await axios.post("leads/signup", params);
      localStorage.setItem("lead_id", JSON.stringify(res.data));
    } catch (error) {
      console.error("Error during createLead operation:", error);
    }
  };

  const init = useCallback(async () => {
    if (!isAuthenticated) return;

    try {
      const currentUserData = await loadCurrentUser();
      if (role === UserType.PARTNER) {
        const customerId = currentUserData?.partners?.[0]?.id ?? "";
        setCurrentUser(currentUserData);
        getPartnerOverview(customerId);
      } else {
        //Checking, if user has accomplished the signup flow
        if (
          !currentUserData?.customers?.length ||
          !currentUserData?.phoneVerifiedAt
        ) {
          await createLead(currentUserData.userId, currentUserData.email);
          navigate(`/signup`);
          return;
        }

        const isSigninOrSignup = ["signin", "signup"].includes(
          splitLocation?.[0]
        );

        const leadId =
          isSigninOrSignup && splitLocation?.[1] === "lead"
            ? splitLocation[2]
            : getItem();

        let customerId =
          currentCustomer?.id ?? currentUserData?.customers?.[0]?.id ?? "";

        if (!isSigninOrSignup && !customerId) {
          customerId = splitLocation?.[0] ?? customerId;
        }

        const customerIdFromLocalStorage = safeParseJSON(
          localStorage.getItem("customer_id") || ""
        );

        const isValidCustomerId = currentUserData?.customers?.some(
          (customer) => customer.id === customerIdFromLocalStorage
        );

        if (
          !isValidCustomerId &&
          currentUserData.customers &&
          currentUserData.customers.length > 0
        ) {
          customerId = currentUserData.customers[0].id ?? "";
          localStorage.setItem("customer_id", JSON.stringify(customerId));
        } else if (isValidCustomerId) {
          customerId = customerIdFromLocalStorage;
        }

        await loadCustomerData(customerId, leadId);
        await handleCustomerData(currentUserData, leadId, customerId);
      }
    } catch (error) {
      setError(error as TCustomError);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (process.env.REACT_APP_FP_ENV !== "PROD") {
      console.log("REACT_APP_FP_ENV", process.env.REACT_APP_FP_ENV);
    }
    i18n.changeLanguage(defaultLanguage);
    dayjs.locale(defaultLanguage);
  }, [defaultLanguage]);

  useEffect(() => {
    if (error_query && error_description) {
      logout();
      setError(error_description);
    }

    init();
  }, [error_query, error_description, init, setError]);

  const closestInstallment = useMemo(() => {
    return calculateAmountToPayForInstallments(loan?.active ?? []);
  }, [loan]);

  let activeInfo;
  let showMyFinancing = false;
  if (currentUser && role === UserType.CUSTOMER && loan && loan.active) {
    activeInfo = {
      totalPaid: closestInstallment?.accumulatedTotal ?? 0,
      dueDate: closestInstallment?.dueToDate,
      currency: closestInstallment?.currency,
    };
  }

  if (
    currentUser &&
    role === UserType.PARTNER &&
    partnerOverview &&
    //@ts-ignore
    partnerOverview.activeCount > 0 //ASK
  ) {
    activeInfo = {
      //@ts-ignore
      activeCount: partnerOverview.activeCount, //ASK
      //@ts-ignore
      activePrincipalSum: partnerOverview.activePrincipalSum, //ASK
    };
  }

  if (loan && (loan.active || (loan.previous && loan.previous.length > 0))) {
    showMyFinancing = true;
  }

  let content;
  if (!isLoading) {
    if (!currentUser?.customers?.length || !currentUser.phoneVerifiedAt) {
      content = (
        <>
          <Routes>
            <Route path="/signup/lead/:lead" element={<Registration />} />
            <Route path="/signup/success" element={<Registration />} />
            <Route path="/signup" element={<Registration />} />
          </Routes>
        </>
      );
    }
    if (isAuthenticated && customerId && currentUser) {
      if (role === UserType.PARTNER) {
        content = (
          <>
            <Header
              customerId={customerId}
              currentUser={currentUser}
              activeInfo={activeInfo}
              getPartnerOverview={getPartnerOverview}
            />
            <main>
              <PartnerRoutes
                customerId={customerId}
                currentUser={currentUser}
                partnerOverview={partnerOverview}
              />
            </main>
            <Footer hideContacts isFixedPosition />
          </>
        );
      } else {
        content = (
          <>
            <Header
              customerId={customerId}
              currentUser={currentUser}
              activeInfo={activeInfo}
              showMyFinancing={showMyFinancing}
              applicationData={applicationData}
              selectCustomerHandler={selectCustomerHandler}
              fetchCustomerRelatedData={fetchCustomerRelatedData}
            />

            <main className={`${!activeInfo && "no-header-info"}`}>
              <CustomerRoutes
                customerId={customerId}
                currentUser={currentUser}
                partner={partner}
                loan={loan}
                applicationData={applicationData}
                leadData={leadData}
                onReloadStatus={handleReloadStatus}
                changePartner={changePartner}
                selectCustomerHandler={selectCustomerHandler}
                updateApplicationData={updateApplicationData}
              />
            </main>

            <Footer isFixedPosition />
          </>
        );
      }
    }

    if (!isAuthenticated) {
      //setting the requested url for navigation
      if (
        splitLocation.length > 0 &&
        !splitLocation.includes("signup") &&
        !splitLocation.includes("signin")
      ) {
        localStorage.setItem(
          "requestedPage",
          splitLocation.join("/") + (search ?? "")
        );
      }

      content = (
        <>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signin" element={<Login />} />
            <Route path="/signin/idp/:idp" element={<Login />} />
            <Route path="/signin/idp/:lead" element={<Login />} />
            <Route path="/signup/lead/:lead" element={<Registration />} />
            <Route path="/signup/success" element={<Registration />} />
            <Route path="/signup" element={<Registration />} />
            <Route
              path="/applications/:id/accept-changed-terms"
              element={<ApplicationTerms customerId="" />}
            />
            <Route
              path="/applications/:id/accept-changed-terms/success"
              element={<ApplicationTerms customerId="" />}
            />
            <Route
              path="/scheduled-agreement-confirmed/:id/"
              element={<ScheduledAgreement userApproval="confirm" />}
            />
            <Route
              path="/scheduled-agreement-rejected/:id/"
              element={<ScheduledAgreement userApproval="reject" />}
            />

            <Route path="/application/:step?/:state?" element={<Login />} />
            <Route
              path="/offer/:partnerName/:merchantID"
              element={
                <main>
                  <Offer customerId="" />
                </main>
              }
            />

            <Route
              path="/entry/:partnerName"
              element={
                <main>
                  <Offer customerId="" />
                </main>
              }
            />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>

          {!pathname.includes("signup") && !pathname.includes("offer") ? (
            <Footer transparent isFixedPosition={isMediumScreen} />
          ) : null}

          {!pathname.includes("signup") && pathname.includes("offer") ? (
            <Footer isFixedPosition={isMediumScreen} />
          ) : null}
        </>
      );
    }
  }

  return (
    <div className="content-wrapper">
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={defaultLanguage}
        dateFormats={{
          normalDate: "DD.MM.YYYY",
        }}
      >
        <Loading isLoading={isLoading || loading} />
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={5000}
          onClose={handleCloseErrorAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseErrorAlert}
            severity="error"
            sx={{ width: "100%" }}
          >
            {parseErrorMessage(error)}
          </Alert>
        </Snackbar>
        {content}
      </LocalizationProvider>
    </div>
  );
}

export default App;
