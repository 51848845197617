import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  FC,
  FormEvent,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';

import { useLoading } from '../../context/loading-context';
import { useErrorHandler } from '../../context/error-handler-context';
import axios from '../../utils/axios';
import {
  ProductsArr,
  ProductPeriod,
  InstallmentPeriod,
  getProductCatalogue,
} from '../../models/products';
import { formatDate, formatMoney } from '../../utils/format';
import { getUtmFromStorage } from '../../utils/utm';

import ApplicationProgress from './Application/ApplicationProgress';
import Slider from '../Sections/Offer/Slider';
import Button from '../UI/Button';
import Input from '../UI/Input';

import styles from './Offer.module.scss';
import { useQuery } from '../../hooks';
import {
  ExternalUserDto,
  IsoCurrencyCode,
  OfferDtoOfferProductDto,
} from '../../types/api';
import { RobotImage } from '../UI/RobotImage';
import {
  Box,
  Container,
  Divider,
  Typography,
  Button as MuiButton,
  Grow,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer,
} from '@mui/material';
import PaymentsDetails from '../Sections/Offer/PaymentsDetails';
import { COLORS } from '../../constants/colors';
import CurrencySwitcher from '../Sections/Offer/CurrencySwitcher';
import PartnerBanner from '../Common/PartnerBanner';
import { useAuth0 } from '@auth0/auth0-react';

interface IOFferProps {
  currentUser?: ExternalUserDto;
  customerId: string;
  selectCustomerHandler?: (customerId: string, user: null) => void;
}

const Offer: FC<IOFferProps> = ({ customerId, selectCustomerHandler }) => {
  const query = useQuery();
  const { isAuthenticated } = useAuth0();

  // support for old API params
  const qMerchantSid = query.get('merchantSid');
  const qBusinessNumber = query.get('businessNumber');

  // new API params
  let qMerchantId = query.get('merchantId');
  const qTenantId = query.get('tenantId');
  let qRegNum = query.get('regNum');
  const qCreatedAt = query.get('createdAt');
  const qSignature = query.get('signature');
  const qCountry = query.get('country');

  if (qMerchantSid && qBusinessNumber) {
    qMerchantId = qMerchantSid;
    qRegNum = qBusinessNumber;
  }

  const authTokenHandler: any = useMemo(
    () => ({ skipAuthToken: !customerId }),
    [customerId]
  );

  const { t } = useTranslation();
  const { regNum, createdAt, signature, partnerName, merchantID } = useParams();
  const navigate = useNavigate();

  const [availableProducts, setAvailableProducts] = useState<
    Array<OfferDtoOfferProductDto>
  >([]);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [lead, setLead] = useState<any>(null);
  const [offerId, setOfferId] = useState<string | null>(null);
  const [currency, setCurrency] = useState<IsoCurrencyCode>(
    IsoCurrencyCode.CZK
  );
  const [customerWithActiveProduct, setCustomerWithActiveProduct] =
    useState(false);
  const [noOfferSubmited, setNoOfferSubmited] = useState(false);
  const [noOffer, setNoOffer] = useState(false);
  const [noOfferData, setNoOfferData] = useState({
    email: '',
    phone: '',
    regNum: '',
  });

  const [postponeLength, setPostponeLength] = useState(0);
  const [tglCrowdfunding, setTglCrowdfunding] = useState(false);
  const [tglProlongation, setTglProlongation] = useState(false);
  const [financingValue, setFinancingValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(50000);
  const [amountComplete, setAmountComplete] = useState(0);
  const [amountCompleteBrutto, setAmountCompleteBrutto] = useState(0);
  const [feeMonthly, setFeeMonthly] = useState(0);
  const [feeProlongation, setFeeProlongation] = useState(0);
  const [feeProlongationTotal, setFeeProlongationTotal] = useState(0);
  const [feePostpone, setFeePostpone] = useState(0);
  const [feePostponeTotal, setFeePostponeTotal] = useState(0);
  const [installments, setInstallments] = useState<Array<any>>([]);
  const [listMarksOdloz, setListMarksOdloz] = useState<Array<any>>([]);
  const [showInstallments, setShowInstallments] = useState<boolean>(false);

  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const updateFinancingValue = (newValue: number) => {
    if (newValue < minValue || newValue > maxValue) {
      return;
    }

    setFinancingValue(newValue);
  };

  const isCZKProduct = availableProducts.some(
    (item) => item.currency === IsoCurrencyCode.CZK
  );
  const isEURProduct = availableProducts.some(
    (item) => item.currency === IsoCurrencyCode.EUR
  );
  const isMultiCurrency = isCZKProduct && isEURProduct;

  const handleCurrencyChange = (
    event: React.MouseEvent<HTMLElement>,
    newCurrency: IsoCurrencyCode
  ) => {
    setCurrency(newCurrency);
    const product = availableProducts.find(
      (i) => i.product === selectedProduct.id && i.currency === currency
    );
    setMinValue(product?.minAmount ? +product.minAmount : 0);
  };

  const handlePostponedPaymentChange = (value: number) => {
    setPostponeLength((currentValue) => (currentValue === value ? 0 : value));
  };

  const calculateInstallmentPlan = useCallback(
    (
      aktProdukt: any,
      loanAmount: number,
      postponeLength: number,
      fromDate: string,
      crowdfunding = false,
      prolongation = false,
      fee?: number
    ) => {
      const round2 = (num: number) => {
        var m = Number((Math.abs(num) * 100).toPrecision(15));
        return (Math.round(m) / 100) * Math.sign(num);
      };

      const productDef = aktProdukt;
      const offeredProduct = availableProducts.find(
        (i: any) => i.product === aktProdukt.id && i.currency === currency
      );

      let celkemSplatky = 0;
      let celkemKomplet = 0;

      let firstDate = dayjs.utc(fromDate).add(3, 'days');

      if (!productDef.postponeEnabled) {
        postponeLength = 0;
      }

      let prolongationLength =
        productDef.prolongationEnabled && (prolongation || crowdfunding)
          ? 1
          : 0;

      const installmentCount = productDef.installmentCount + prolongationLength;
      const installmentAmount = Math.ceil(
        loanAmount / (productDef.installmentCount - postponeLength)
      );

      const feeMonthly =
        fee === undefined
          ? Math.ceil(
              round2(
                round2(((offeredProduct?.ratePerc ?? 0) / 100) * loanAmount) *
                  productDef.interestRateMultiplier
              )
            )
          : Math.ceil(
              round2(
                round2((fee / 100) * loanAmount) *
                  productDef.interestRateMultiplier
              )
            );

      const postponeFeeMultiplier =
        postponeLength > 0
          ? crowdfunding
            ? productDef.postponeFeeMultiplierCF
            : productDef.postponeFeeMultiplier
          : 1;
      const postponeFee =
        postponeLength > 0 ? postponeFeeMultiplier * feeMonthly : 0;

      const prolongationFeeMultiplier =
        prolongationLength > 0
          ? crowdfunding
            ? productDef.prolongationFeeMultiplierCF
            : productDef.prolongationFeeMultiplier
          : 1;
      const prolongationFee =
        prolongationLength > 0 ? prolongationFeeMultiplier * feeMonthly : 0;

      let nextDate = dayjs(); //to avoid type problems
      let installments = [];

      for (let i = 0; i < installmentCount; i++) {
        if (productDef.installmentPeriod === InstallmentPeriod.Month) {
          nextDate = dayjs.utc(firstDate);
          nextDate = nextDate.add(i + 1, 'month');
        } else {
          nextDate = nextDate.add(
            7 * productDef.installmentPeriodCount,
            'days'
          );
        }

        const isLast = i + 1 === installmentCount;
        const isPostponed = postponeLength > 0 && i < postponeLength;
        const isProlonged = prolongationLength > 0 && i < prolongationLength;
        const amount =
          isPostponed || isProlonged || (productDef.baloon && !isLast)
            ? 0
            : isLast
            ? loanAmount - celkemSplatky
            : installmentAmount;
        const fee =
          feeMonthly +
          (isPostponed ? postponeFee : 0) +
          (isProlonged ? prolongationFee : 0);

        celkemSplatky = celkemSplatky + amount;
        celkemKomplet = celkemKomplet + amount + fee;

        installments.push({
          index: i + 1,
          principal: amount,
          fee: fee,
          dueDate: nextDate.toISOString().slice(0, 10),
        });
      }

      const postponeFeeDef =
        productDef.postponeEnabled && postponeLength > 0
          ? productDef.postponeFeeMultiplier * feeMonthly * postponeLength
          : 0;
      const prolongationFeeDef = productDef.prolongationEnabled
        ? productDef.prolongationFeeMultiplier * feeMonthly
        : 0;

      return {
        product: aktProdukt,
        productId: productDef.id,
        baloon: productDef.baloon,
        crowdfunding: crowdfunding,

        amount: loanAmount,
        feeMonthly: feeMonthly,
        amountComplete: celkemKomplet,
        amountCompleteBrutto:
          celkemKomplet +
          (crowdfunding ? postponeFeeDef + prolongationFeeDef : 0),

        postponeLength: postponeLength,
        feePostponeMonth:
          productDef.postponeEnabled && postponeLength > 0
            ? postponeFeeMultiplier * feeMonthly
            : 0,
        feePostponeTotal:
          productDef.postponeEnabled && postponeLength > 0
            ? postponeFeeMultiplier * feeMonthly * postponeLength
            : 0,
        feePostpone: postponeFeeDef,

        prolongation: prolongation,
        prolongationLength: prolongationLength,
        feeProlongation: prolongationFeeDef,
        feeProlongationTotal: prolongationFee,

        installments: installments,
      };
    },
    [availableProducts, currency]
  );

  const handlePostponeToggle = (oldSelected: boolean) => {
    setTglProlongation(!oldSelected);

    if (oldSelected) {
      setTglCrowdfunding(!oldSelected);
    }
  };

  const onToggleShowInstallmetns = (): void => {
    setShowInstallments((prevState) => !prevState);
  };

  const componentPostponeBox = () => {
    if (
      [ProductPeriod.M1, ProductPeriod.M3].includes(selectedProduct.typProduktu)
    ) {
      const dateOfInstallment = new Date();
      dateOfInstallment.setDate(dateOfInstallment.getDate() + 3);
      dateOfInstallment.setMonth(dateOfInstallment.getMonth() + 2);
      return (
        <Stack justifyContent="center" direction="row">
          <MuiButton
            onClick={() => handlePostponeToggle(tglProlongation)}
            sx={{
              borderRadius: '10px',
              minWidth: '230px',
              height: '52px',
              color: tglProlongation ? 'common.white' : COLORS.text,
              backgroundColor: tglProlongation ? '#BBC9D7' : 'background.paper',
              border: '1px solid',
              borderColor: tglProlongation ? '#BBC9D7' : '#BBC9D7',
              '&:hover': {
                backgroundColor: tglProlongation ? '#BBC9D7' : 'action.hover',
                color: tglProlongation ? 'common.white' : COLORS.text,
              },
            }}
          >
            <Typography variant="h6" fontWeight="600" fontSize="0.875rem">
              {t('common-by-one-month')}
            </Typography>
            {/* &nbsp;
            <Typography>{formatDate(dateOfInstallment)}</Typography> */}
          </MuiButton>
        </Stack>
      );
    } else if (
      [ProductPeriod.M6, ProductPeriod.M12].includes(
        selectedProduct.typProduktu
      )
    ) {
      return (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 1 }}
          mt="28px"
          justifyContent="center"
          alignItems={{ xs: 'center', sm: 'flex-start' }}
        >
          {listMarksOdloz.map((item) => {
            const selectedButton = postponeLength === item.value;
            return (
              <MuiButton
                key={item.value}
                onClick={() => handlePostponedPaymentChange(item.value)}
                sx={{
                  borderRadius: '10px',
                  padding: '1rem',
                  color: selectedButton ? 'common.white' : COLORS.text,
                  backgroundColor: selectedButton
                    ? '#BBC9D7'
                    : 'background.paper',
                  border: '1px solid',
                  borderColor: selectedButton ? '#BBC9D7' : '#BBC9D7',
                  '&:hover': {
                    backgroundColor: selectedButton
                      ? '#BBC9D7'
                      : 'action.hover',
                    color: selectedButton ? 'common.white' : COLORS.text,
                  },
                  width: { xs: '100%', sm: '50%' },
                  mb: { xs: 1, sm: 0 },
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  {item.value === 1
                    ? t('common-by-one-month')
                    : t('common-by-two-months')}
                </Typography>
                {/* &nbsp;
                <Typography>{item.label}</Typography> */}
              </MuiButton>
            );
          })}
        </Stack>
      );
    }
  };

  const handleProductSelection = () => {
    const currencyProducts = availableProducts.filter(
      (product: any) => product.currency === currency
    );

    let productWithHighestMaxAmount: any;

    if (currencyProducts.length > 0) {
      productWithHighestMaxAmount = currencyProducts.reduce(
        (prevProduct: any, currentProduct: any) => {
          const prevMaxAmount = parseInt(prevProduct.maxAmount, 10);
          const currMaxAmount = parseInt(currentProduct.maxAmount, 10);

          //check the highest amount product
          if (currMaxAmount > prevMaxAmount) {
            return currentProduct;
          } else if (currMaxAmount === prevMaxAmount) {
            //check the longest term product
            const prevMValue =
              parseInt(prevProduct.product.match(/\d+/)[0], 10) || 0;
            const currMValue =
              parseInt(currentProduct.product.match(/\d+/)[0], 10) || 0;

            return currMValue > prevMValue ? currentProduct : prevProduct;
          } else {
            return prevProduct;
          }
        }
      );
    } else {
      productWithHighestMaxAmount = { product: {} };
    }

    const productDef =
      getProductCatalogue()[productWithHighestMaxAmount.product];

    setSelectedProduct(productDef);
  };

  const handleOfferInterested = async () => {
    const product = availableProducts.find(
      (i: any) => i.product === selectedProduct?.id
    );

    const offerData = {
      offerId,
      partnerCode: partnerName,
      merchantId: merchantID,
      productParams: {
        type: product?.product,
        amount: financingValue,
        currency,
        postponedPeriods: postponeLength,
        prolonged: tglProlongation,
        crowdfunded: false,
      },
    };

    setLoading(true);
    axios
      .post(`leads/interest`, offerData, authTokenHandler)
      .then((res) => {
        setLoading(false);
        const leadId = res.data.id;
        const existingCustomer = res.data.existingCustomer;

        if (customerId) {
          setLoading(true);
          axios
            .post(`partner-connections/customers/${customerId}`, {
              leadId: leadId,
            })
            .then(() => {
              setLoading(false);
              selectCustomerHandler && selectCustomerHandler(customerId, null);
            })
            .catch((err) => {
              setLoading(false);
              setError(err);
            });
        } else if (existingCustomer) {
          navigate(`/signin/lead/${leadId}`);
        } else {
          localStorage.setItem('lead_id', JSON.stringify(leadId));
          navigate(`/signup/lead/${leadId}`);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  useEffect(() => {
    if (
      availableProducts.length > 0 &&
      availableProducts.every(
        (product: any) => product.currency === IsoCurrencyCode.EUR
      )
    ) {
      setCurrency(IsoCurrencyCode.EUR);
      const product = availableProducts.find(
        (i) => i.product === selectedProduct?.id && i.currency === currency
      );
      setMinValue(product?.minAmount ? +product.minAmount : 0);
    }
  }, [availableProducts]);

  useEffect(() => {
    setLoading(true);

    const utm = getUtmFromStorage();

    // is it entry page?
    if (!merchantID) {
      let postData: Record<string, any> = {
        partnerCode: partnerName,
        merchantId: qMerchantId,
      };
      if (qRegNum) postData.regNum = qRegNum;
      if (qCreatedAt) postData.createdAt = qCreatedAt;
      if (qSignature) postData.signature = qSignature;
      if (qTenantId) postData.tenantId = qTenantId;
      if (qCountry) postData.country = qCountry;

      if (utm) {
        postData = {
          ...postData,
          ...utm,
        };
      }

      axios
        .post(`leads/partner`, postData, authTokenHandler)
        .then((res) => {
          setLoading(false);

          if (res.data) {
            handleLeadsPartnerResponse(res.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        });

      return;
    }

    if (customerId) {
      axios
        .get(`offers/active/customer/${customerId}`, authTokenHandler)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            if (res.data.customerWithActiveProduct) {
              setCustomerWithActiveProduct(true);
            } else {
              let postData = {
                offerId: res.data.id,
                customerId: customerId,
              };

              if (utm) {
                postData = {
                  ...postData,
                  ...utm,
                };
              }
              setAvailableProducts(res.data.products);

              setLoading(true);
              axios
                .post(`leads/opportunity`, postData, authTokenHandler)
                .then((res2) => {
                  setLoading(false);

                  setOfferId(res.data.id);
                  setLead(res2.data.id);
                })
                .catch((err) => {
                  setLoading(false);
                  setError(err);
                });
            }
          } else {
            const postData = {
              partnerCode: partnerName,
              merchantId: merchantID,
            };

            setLoading(true);
            axios
              .post(`leads/no-offer`, postData, authTokenHandler)
              .then((res2) => {
                setLoading(false);
                setLead(res2.data.id);
                setNoOffer(true);
              })
              .catch((err) => {
                setLoading(false);
                setError(err);
              });
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        });
    } else {
      let postData = { partnerCode: partnerName, merchantId: merchantID };

      if (utm) {
        postData = {
          ...postData,
          ...utm,
        };
      }

      axios
        .post(`leads/partner`, postData, authTokenHandler)
        .then((res) => {
          setLoading(false);

          if (res.data) {
            handleLeadsPartnerResponse(res.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        });
    }
  }, [
    authTokenHandler,
    createdAt,
    customerId,
    merchantID,
    partnerName,
    qCreatedAt,
    qMerchantId,
    qRegNum,
    qSignature,
    qTenantId,
    regNum,
    setError,
    setLoading,
    signature,
  ]);

  useEffect(() => {
    handleProductSelection();
  }, [availableProducts, currency]);

  const handleLeadsPartnerResponse = (data: any) => {
    if (data.lead.state === 'SERVICE_ACTIVATED') {
      console.log(data.lead.id)
      setLead(data.lead.id);
      localStorage.setItem('lead_id', JSON.stringify(data.lead.id));

      if (data.lead.existingCustomer) {
        navigate(`/signin/lead/${data.lead.leadId}`);
      } else {
        navigate(`/signup/lead/${data.lead.leadId}`);
      }

    } else if (data.lead.state === 'NO_OFFER') {
      setNoOffer(true);
      setLead(data.lead.id);
    } else if (data.offer && data.offer.customerWithActiveProduct) {
      setCustomerWithActiveProduct(true);
      setOfferId(data.offer.id);
      setLead(data.lead.id);
    } else {
      const products = data.offer.products;
      const lastProduct = products[products.length - 1];
      const productDef = getProductCatalogue()[lastProduct.product];

      setAvailableProducts(products);
      setSelectedProduct(productDef);
      setOfferId(data.offer.id);
      setLead(data.lead.id);
    }
  };

  useEffect(() => {
    if (!selectedProduct) {
      return;
    }

    const product = availableProducts.find(
      (i) => i.product === selectedProduct.id && i.currency === currency
    );
    if (
      selectedProduct &&
      [ProductPeriod.M6, ProductPeriod.M12].includes(
        selectedProduct.typProduktu
      )
    ) {
      let listOdlozeni = [];
      let nextDate = new Date();
      nextDate.setDate(nextDate.getDate() + 3);

      for (let i = 0; i < 3; i++) {
        nextDate.setMonth(nextDate.getMonth() + 1);

        if (i === 0) {
          continue; // omiting 1 month
        }

        listOdlozeni.push({
          value: i,
          label: formatDate(nextDate),
        });
      }

      setListMarksOdloz(listOdlozeni);
    } else {
      setListMarksOdloz([]);
    }

    setMaxValue(product?.maxAmount ? +product.maxAmount : 0);
    setMinValue(product?.minAmount ? +product.minAmount : 0);
    setFinancingValue(product?.maxAmount ? +product.maxAmount : 0);
    setTglProlongation(false);
    setTglCrowdfunding(false);
    setPostponeLength(selectedProduct.typProduktu === ProductPeriod.M3 ? 2 : 0);
  }, [availableProducts, selectedProduct, currency]);

  useEffect(() => {
    if (!financingValue || !selectedProduct) {
      return;
    }

    const plan = calculateInstallmentPlan(
      selectedProduct,
      financingValue,
      postponeLength,
      new Date().toDateString(),
      tglCrowdfunding,
      tglProlongation
    );

    const installments = plan.installments.map((i) => {
      return {
        key: i.index,
        poradiSplatky: i.index,
        datum: new Date(i.dueDate),
        castka: i.principal + i.fee,
      };
    });

    setAmountComplete(plan.amountComplete);
    setAmountCompleteBrutto(plan.amountCompleteBrutto);
    setFeeMonthly(plan.feeMonthly);
    setFeeProlongation(plan.feeProlongation);
    setFeeProlongationTotal(plan.feeProlongationTotal);
    setFeePostpone(plan.feePostpone);
    setFeePostponeTotal(plan.feePostponeTotal);
    setInstallments(installments);
  }, [
    calculateInstallmentPlan,
    financingValue,
    postponeLength,
    selectedProduct,
    tglCrowdfunding,
    tglProlongation,
  ]);

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();

    const data = {
      ...noOfferData,
      leadId: lead,
    };

    setLoading(true);
    axios
      .post('leads/contact', data, authTokenHandler)
      .then((res) => {
        setNoOfferSubmited(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  if (noOffer || customerWithActiveProduct) {
    const secondaryInfo = noOffer
      ? t('offer-no-offer')
      : t('offer-no-offer-alt');

    if (noOfferSubmited) {
      return (
        <div className={styles['overlay-form-wrapper']}>
          <RobotImage />

          <div className={styles['form-wrapper']}>
            <div className="gradient-bg">
              <h1>{t('offer-successfully-sent')}</h1>
            </div>

            <div className={styles.button}>
              <Button
                type="button"
                className="gradient"
                onClick={() => navigate(`/${customerId}/overview`)}
              >
                <span>{t('offer-go-to-website')}</span>
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles['overlay-form-wrapper']}>
        <RobotImage />

        <div className={styles['form-wrapper']}>
          <div className="gradient-bg">
            <h1>{t('offer-pleased')}</h1>
            <h2>{secondaryInfo}</h2>
          </div>

          {noOffer && (
            <form className={styles.form} onSubmit={submitHandler}>
              <div className={styles.line}>
                <Input
                  id="phone"
                  label={t('offer-phone')}
                  value={noOfferData.phone}
                  pattern="^\+(?:[0-9] ?){6,14}[0-9]$"
                  placeholder={t('registration.phone-number-placeholder')}
                  required
                  type="text"
                  onChange={(value: any) => {
                    setNoOfferData((prev) => {
                      return { ...prev, phone: value.target.value };
                    });
                  }}
                />
              </div>

              <div className={styles.line}>
                <Input
                  id="email"
                  label={t('offer-email')}
                  value={noOfferData.email}
                  type="email"
                  required
                  onChange={(value: any) => {
                    setNoOfferData((prev) => {
                      return { ...prev, email: value.target.value };
                    });
                  }}
                />
              </div>

              <div className={styles.line}>
                <Input
                  id="regNum"
                  label={t('offer-regnum')}
                  type="number"
                  value={noOfferData.regNum}
                  required
                  onChange={(value: any) => {
                    setNoOfferData((prev) => {
                      return { ...prev, regNum: value.target.value };
                    });
                  }}
                />
              </div>

              <div className={styles.line}>
                <div className={styles.actions}>
                  <Button type="submit" className="gradient form">
                    <span>{t('offer-want-offer')}</span>
                  </Button>
                </div>
              </div>
            </form>
          )}

          {customerWithActiveProduct && (
            <div className={styles.button}>
              <Button
                type="button"
                className="gradient"
                onClick={() => navigate('/signin')}
              >
                <span>{t('offer-login-to-website')}</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
  if (!selectedProduct || !financingValue) return;

  return (
    <>
      <PartnerBanner partnerName={partnerName} />

      <Container
        maxWidth="sm"
        sx={{
          margin: isAuthenticated ? '2rem auto' : 'auto',
          overflow: 'hidden',
        }}
      >
        {isMultiCurrency ? (
          <CurrencySwitcher
            currency={currency}
            handleCurrencyChange={handleCurrencyChange}
          />
        ) : null}
        <Typography
          variant="h6"
          fontWeight="bold"
          textAlign="center"
          sx={{
            display: { xs: 'none', sm: 'block' },
            fontSize: '1rem',
            marginBottom: '10px',
          }}
        >
          {t('offer-new-financing')}
        </Typography>
        <Slider
          value={financingValue}
          minValue={minValue}
          maxValue={maxValue}
          updateValue={updateFinancingValue}
          currency={currency}
        />

        <Typography
          variant="h6"
          fontWeight="bold"
          textAlign="center"
          sx={{
            marginTop: '1.875rem',
            marginBottom: { xs: 0, sm: '.625rem' },
            fontSize: '1rem',
          }}
        >
          {t('offer-how-long')}
        </Typography>
        <div className={styles['products-wrapper']}>
          {ProductsArr.filter((p) => {
            const isAvailable = availableProducts.some(
              (i) => i.product === p.code && i.currency === currency
            );

            // If product is M3, we need to check for M3R availability
            if (p.code === 'M3') {
              const isM3RAvailable = availableProducts.some(
                (i) => i.product === 'M3R' && i.currency === currency
              );
              return !isM3RAvailable && isAvailable;
            }

            // If product is M3R, return it only if M3R is available
            if (p.code === 'M3R') {
              return isAvailable;
            }

            // For all other products, return them if they are available
            return isAvailable;
          }).map((p) => {
            const productDef = getProductCatalogue()[p.code];
            const isAvailable = Boolean(
              availableProducts.find(
                (i) => i.product === productDef.id && i.currency === currency
              )
            );

            return (
              <button
                key={p.code}
                className={`${isAvailable && styles.offerd} ${
                  selectedProduct?.id === p.code && styles.selected
                }`}
                onClick={
                  isAvailable ? () => setSelectedProduct(productDef) : undefined
                }
              >
                {productDef.text}
              </button>
            );
          })}
        </div>

        <Typography
          variant="h6"
          textAlign="center"
          fontWeight="bold"
          margin="1.875rem 0 0.625rem 0"
          sx={{ fontSize: '1rem' }}
        >
          {t('offer-when-start-paying')}
        </Typography>

        {componentPostponeBox()}

        <PaymentsDetails
          amountComplete={amountComplete}
          feeMonthly={feeMonthly}
          financingValue={financingValue}
          currency={currency}
          prolongationFee={feeProlongationTotal}
          postponementFee={feePostponeTotal}
        />

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={{ xs: 1.6, sm: 0 }}
          textAlign="center"
          width="100%"
          sx={{ marginBottom: { sm: '1.4rem', xs: '1.6rem' } }}
        >
          <MuiButton
            sx={{
              p: 1.6,
              borderRadius: 1.6,
              color: 'white',
              width: { xs: '100%', sm: '330px' },
              mb: { xs: 1.6, sm: 0 },
            }}
            color="secondary"
            variant="contained"
            disableElevation
            onClick={handleOfferInterested}
            id="calc_interest"
          >
            <Typography
              fontWeight="bold"
              color="white"
              variant="h4"
              sx={{ fontSize: '1rem' }}
            >
              {t('offer-interested-alt')}
            </Typography>
          </MuiButton>
          <Typography
            sx={{
              padding: '10px',
              color: COLORS.text,
              cursor: 'pointer',
              fontWeight: 700,
              fontSize: '0.875rem',
              alignSelf: 'center',
              '&:hover': {
                background: 'none',
              },
              width: { xs: '100%', sm: 'auto' },
              textAlign: 'center',
              mt: { xs: 1.6, sm: 0 },
              textDecoration: 'underline',
              textDecorationThickness: '2px',
              textUnderlineOffset: '.3rem',
              lineHeight: 1.5,
            }}
            variant="h6"
            onClick={onToggleShowInstallmetns}
          >
            {t('offer-show-installments')}
          </Typography>
        </Stack>

        {showInstallments ? (
          <Grow in={showInstallments}>
            <Box
              sx={{
                border: '1px solid #D7DBDC',
                borderRadius: '1rem',
                paddingTop: '1.6rem',
                paddingBottom: '0.9rem',
                marginBottom: '1.4rem',
              }}
            >
              <Typography
                fontWeight="bold"
                variant="h4"
                textAlign="center"
                mb="16px"
              >
                {t('offer-installments-overview')}
              </Typography>
              <TableContainer
                sx={{
                  maxWidth: { xs: '100%', sm: '650px' },
                  overflow: 'hidden',
                }}
              >
                <Table
                  sx={{ minWidth: { xs: '400', sm: '650px' }, width: '100%' }}
                >
                  <TableBody>
                    {installments.map((installment) => (
                      <TableRow key={installment.poradiSplatky}>
                        <TableCell
                          sx={{
                            fontSize: { xs: '13px', sm: '16px' },
                            fontWeight: 700,
                            color: COLORS.text,
                            padding: { xs: '5px 6.5px', sm: '5px 10px' },
                            borderBottom: 'none',
                          }}
                          align="center"
                        >
                          {formatDate(installment.datum)}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { xs: '13px', sm: '16px' },
                            color: COLORS.text,
                            fontWeight: 500,
                            padding: { xs: '5px 6.5px', sm: '5px 10px' },
                            borderBottom: 'none',
                            display: { xs: 'none', sm: 'table-cell' },
                          }}
                        >
                          {installment.poradiSplatky}. {t('offer-installment')}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: { xs: '5px 6.5px', sm: '5px 10px' },
                            width: '150px',
                            borderBottom: 'none',
                          }}
                        >
                          <Divider sx={{ width: '100%' }} />
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { xs: '13px', sm: '16px' },
                            fontWeight: 700,
                            color: COLORS.text,
                            padding: { xs: '5px 6.5px', sm: '5px 10px' },
                            borderBottom: 'none',
                          }}
                        >
                          {formatMoney(installment.castka, currency, false)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grow>
        ) : null}

        <Box sx={{ display: { xs: 'hidden', sm: 'visible' } }}>
          <ApplicationProgress
            partner={{ code: partnerName ?? '', merchantId: merchantID ?? '' }}
            application={{ inputState: 'OFFER' } as any}
            customerId={customerId}
          />
        </Box>
      </Container>
    </>
  );
};

export default Offer;
